/* eslint-disable quotes */
module.exports = {
  "en": {
    //new intl entries
    "only.questions": "Only questions",
    "questions.observations": "Questions and observations",
    "only.observations": "Only observations",
    "text.search": "Text search",
    "locations.active": "Active locations",
    "user.rights": "User rights",
    "user.rights.normal": "Normal",
    "user.rights.admin": "Admin",
    "tenants.active": "Active customers",
    "fallback.question.text": "Translation missing for selected language",
    "fallback.question": "No question",
    "my.tasks": "My tasks",
    
    "yes": "Yes",
    "no": "No",
    "is.allright": "Is allright?",
    "comment.add": "Add comment",
    "cancel": "Cancel",
    "created": "Created",
    "date": "Date",
    "time": "Time",
    "delete": "Delete",
    "description": "Description",
    "dueDate": "Due by",
    "dueDate.undefined": "Undefined",
    "due.in": "On due",
    "edit": "Edit",
    "form": "Form template",
    "observation": "Observation",
    "observations": "Observations",
    "location": "Location",
    "name": "Object definition",   
    "ok": "OK",
    "close": "Close",
    "priority": "Priority",
    "remove": "Remove",
    "save": "Save",
    "status": "Status",
    "subject": "Search from text",
    "attachments": "Attachments",
    "attachments.description": "You can upload ...",
    "task": "Action/proposal",
    "tasks": "Tasks",
    "risk": "Risk",
    "keyWords": "Consequence/cause",
    "low": "Low",
    "normal": "Normal",
    "high": "High",
    "severity": "Severity",
    "minor": "Minor",
    "moderate": "Moderate",
    "severe": "Severe",
    "assigned": "Assigned",
    "add.keywords": "Add keyword(s)",
    "reported.by": "Reported By",
    "updated.by": "Updated By",
    "updated": "Updated",
    "select": "Select",
    "section": "Section",
    "assessmentForm": "Form template",
    "assessment": "Assessment",
    "undefined": "Undefined",
    "good": "Good",
    "month": "Month",
    "reporter": "Reporter",
    "unlikely": "Occasional",
    "probable": "Recurring",
    "likely": "Constant",
    "probability": "Probability",
    "add.new": "Add new",
    "dateformat.invalid": "Invalid date format",
    "start.assessment": "Start new",
    "remove.location": "Remove location",
    "task.open": "Open",
    "task.completed": "Completed",
    "search.placeholder": "What are you searching for?",
    "search.tooltip": "In addition to letters and digits, you can use only a space character and character -.",
    "riskmanagement.title": "Risk Management",
    "observations.title": "Observations",
    "observations.new": "New Observation",
    "observations.remove": "Remove observation",
    "observations.remove.confirm": "Do you want to remove observation '{name}'? All related data (attachments and tasks) will be lost.",
    "observationDate": "Date",
    "observationTime": "Time",
    "observation.new.title": "Add new observation",
    "observations.link.add": "Add observation",
    "observation.subject": "Observation",
    "observation.tasks": "Tasks",
    "observations.task.add": "Add task",
    "observation.description": "Description",
    "tasks.title": "Tasks",
    "tasks.new": "New task",
    "task.new.title": "New task",
    "tasks.complete": "Complete task",
    "tasks.mail.to": "Mail task",
    "tasks.remove": "Remove task",
    "tasks.remove.confirm": "Do you want to remove task '{name}'? All related data will be lost.",
    "task.status": "Implementation",
    "task.status.new": "Not started",
    "task.status.open": "Unfinished",
    "task.status.completed": "Completed",
    "task.status.rejected": "Not implemented",
    "tasks.open": "Open tasks",
    "assessments": "Assessments",
    "assessments.title": "Assessments",
    "assessments.form": "Assessment Form template",
    "assessments.new": "New Assessment",
    "assessment.start": "Start assessment",
    "assessments.open": "Assessments",
    "assessments.remove": "Remove assessment",
    "assessments.remove.confirm": "Do you want to remove assessment '{name}'? All related data (observations and tasks) will be left in the system.",
    "assessment.create.new": "Start new",
    "assessment.status.open": "Open",
    "assessment.status.completed": "Completed",
    "assessment.status.cancelled": "Cancelled",
    "assessment.name": "Object definition",
    "locations.title": "Locations",
    "locations.new": "New Location",
    "location.new.title": "New location",
    "locations.remove": "Remove location",
    "locations.remove.confirm": "Do you want to remove location '{name}'? All related sublocations and data (observations and tasks) will be removed from the system.",
    "assessmentforms": "Form templates",
    "assessmentforms.title": "Form templates",
    "assessmentforms.title.addnew": "Create new form template",
    "assessmentforms.new": "New form template",
    "assessmentforms.remove": "Remove form template",
    "assessmentforms.remove.confirm": "Do you want to remove form template '{name}'? Assessments or monitorings using this form will be kept in the system, as well as all related data (observations and tasks). New assessments and monitorings using this form template cannot be made.",
    "attachment.remove": "Remove attachment",
    "attachment.remove.confirm": "Do you want to remove attachment?",
    "upload.add.file": "Add a picture",
    "upload.take.photo": "Take a photo",
    "upload.failed": "Unable to upload this file",
    "upload.failed.too.large": "File too large for uploading",
    "session.expired.title": "Session expired",
    "session.expired.content": "You need to reload page",
    "reload": "Reload",
    "comment.comments": "Comments",
    "comment.comment": "Comment",
    "comment.commenttext": "Comment",
    "comment.store": "Send",
    "comment.reply": "Reply",
    "administration.tenant": "Administration",
    "administration.system": "System Administration",
    "users.title": "Users",
    "users.remove": "Remove user",
    "users.remove.confirm": "Do you want to remove user '{name}'? All related data will be lost.",
    "tenants.title": "Customers",
    "tenants.remove": "Remove customer organization",
    "tenants.remove.confirm": "Do you want to remove customer organization '{name}'? All related data will be lost.",
    "tenant.new.title": "New tenant",
    "userId": "User ID",
    "firstName": "First name",
    "lastName": "Last name",
    "email": "Email",
    "tenant": "Customer organization",
    "questiongroups.title": "Question Groups",
    "questionGroup": "Question Group",
    "titles.fi": "Title",
    "titles.sv": "Title",
    "titles.en": "Title",
    "question": "Question",
    "question.text": "Text",
    "question.instructions": "Instructions",
    "title": "Title",
    "questionGroup.new.title": "New Question Group",
    "language": "Language",
    "anon.observation.link": "Open link to observation without sign-in",
    "signin.heading": "Pohjola Risk Management Service Log in",
    "signin.username": "Username",
    "signin.password": "Password",
    "signin.cancel": "Cancel",
    "signin.submit": "Log in",
    "signin.invalid": "Invalid username or password. Due to an update in the service all passwords need to be renewed. Please use Recover forgotten password functionality to renew password.",
    "signin.unexpected": "Something unexpected happened. Please try again or contact the admin.",
    "signin.retry": "Error. Please retry again shortly.",
    "signin.logout": "Log out",
    "signin.logout.header": "Confirm log out",
    "signin.changepwd": "Change password",
    "signin.changepwd.old": "Old password",
    "signin.feedback": "Click here to send feedback about this issue.",
    "signin.user-expired": "User account has expired.",
    "register.password": "Password",
    "register.password_check": "Confirm password",
    "register.unexpected": "Something unexpected happened. Please contact the administrator.",
    "signin.error.password_format": "Password must be at least 8 characters long, containing upper and lower case letters and numbers",
    "signin.error.register_password_mismatch": "Passwords must match",
    "signin.needsLoginFirst": "You need to log in with your temporary password before you can initiate password change. {signinLink}",
    "signin.signinLink": "Proceed to sign-in page",
    "user.in.limbo": "You do not have access to any client data yet. Your account registration still waits for actions. Please try to log in later.",
    "terms_and_conditions": "Terms and conditions",
    "terms_and_conditions.content": "Placeholder for the terms and conditions that the user has to agree to.",
    "terms_and_conditions.accept": "Accept the terms and conditions",
    "forgotPassword": "Recover forgotten password",
    "passwordRecovery": "Recover forgotten password",
    "recoverPassword": "Recover",
    "recovery.complete.text": "A password recovery validation code has been sent to {email}. ",
    "recovery.tempCodeResent": "A temporary password has been sent to {email}. Please log in with that password.",
    "recovery.verifylink": "Proceed to validation",
    "recoveryVerify": "Password recovery validation",
    "verify.code": "Validation code",
    "verify.newpassword.explain": "You must choose a new password for yourself",
    "activate": "Activate",
    "register.validationCode": "Incorrect validation code",
    "termsAccepted": "Terms and conditions accepted",
    "done": "Done",
    "user": "User",
    "user.new.title": "Add new user",
    "tenants": "Customers",
    "send": "Send",
    "monitorings": "Monitorings",
    "monitorings.title": "Monitoring",
    "monitorings.form": "Monitoring form templates",
    "monitoringForm": "Monitoring form template",
    "monitorings.remove": "Remove monitoring",
    "monitorings.remove.confirm": "Do you want to remove monitoring '{name}'?  All related data (observations and tasks) will be kept in the system.",
    "monitorings.new": "Start new monitoring",
    "monitorings.start": "Start monitoring",
    "monitorings.open": "Open monitorings",
    "monitoring": "Monitoring",
    "participants": "Participants",
    "participants.add": "Add new participant",
    "participant": "Participant",
    "form.type": "Form template type",
    "form.type.assessment": "Assessment",
    "form.type.monitoring": "Monitoring",
    "monitorings.setcompleted": "Complete",
    "risk.tooltip.good_undefined": "Positive",
    "risk.tooltip.minor_undefined": "Minor",
    "risk.tooltip.minor_unlikely": "Insignificant risk\nMinor Unlikely",
    "risk.tooltip.minor_probable": "Low risk\nMinor Probable",
    "risk.tooltip.minor_likely": "Medium risk\nMinor Likely",
    "risk.tooltip.moderate_undefined": "Moderate",
    "risk.tooltip.moderate_unlikely": "Low risk\nModerate Unlikely",
    "risk.tooltip.moderate_probable": "Medium risk\nModerate Probable",
    "risk.tooltip.moderate_likely": "High risk\nModerate Likely",
    "risk.tooltip.severe_undefined": "Severe",
    "risk.tooltip.severe_unlikely": "Medium risk\nSevere Unlikely",
    "risk.tooltip.severe_probable": "High risk\nSevere Probable",
    "risk.tooltip.severe_likely": "Extreme risk\nSevere & likely",
    "filter.show_all": "Show all",
    "monitoring.status.completed": "Complete",
    "monitoring.status.open": "Open",
    "monitoring.name": "Object definition",
    "permission.create": "Add",
    "permission.update": "Modify",
    "permission.delete": "Delete",
    "permission.read": "Read",
    "roles": "Roles",
    "roles.add": "Add role",
    "roles.viewer": "Read",
    "roles.user": "Use",
    "roles.manager": "Manage",
    "roles.fromParents": "Roles from upper organisation levels",
    "roles.remove": "Remove role",
    "roles.remove.confirm": "Do you want to remove role '{role}' from '{name}'?",
    "location.parent": "Upper organisation level",
    "select.create.option": "New option \"{label}\"",
    "related.kept.confirm": "The following related {associatedResources} will be kept, only the form template is removed.",
    "related.none": "No {associatedResources} have been associated with this {resource}",
    "related.removed.confirm": "The following related {associatedResources} will be removed",
    "admin": "Admin",
    "default": "Default",
    "role": "Role",
    "admin.stats.new_observations": "New observations",
    "admin.stats.new_tasks": "New tasks",
    "admin.stats.new_assessments": "New assessments",
    "admin.stats.new_monitorings": "New monitorings",
    "admin.stats.active_users": "Active users",
    "admin.stats.summary": "Total summary",
    "admin.stats.positive_observation_rate": "Positive observation rate",
    "admin.stats.tasks_close_rate_total": "Task close rate total",
    "admin.stats.tasks_close_rate_due_time": "Task close rate in due time",
    "admin.stats.no_deadline_tasks": "Tasks without deadline",
    "admin.stats.overdue_tasks": "Tasks overdue",
    "admin.stats.location_stats.title": "Summary of location data",
    "admin.stats.completed_tasks": "Tasks completed",
    "admin.stats.last_month_stats": "Last month statistics",
    "admin.stats.tenant_stats.title": "Summary of customer data",
    "general": "General",
    "feedback.send": "Send feedback",
    "feedback.praise": "Positive",
    "feedback.propose-feature": "Proposal",
    "feedback.problem": "Problem",
    "feedback.thanks": "Thank you for your feedback!",
    "feedback.error": "An error occurred while sending feedback!",
    "source": "Source",
    "anon.thank.you": "Thank you!",
    "notifications.settings.disable": "Do not notify me by e-mail when:",
    "notifications.task_assign": "A task is assigned to me",
    "notifications.observation_create": "An observation is created",
    "notifications.observation_assign": "An observation is assigned to me",
    "my.user.details": "My user details",
    "tooltip.data-protection": "Do not enter personal data or add pictures, where persons can be idenfied. ",
    "businessId": "Business ID",
    "customerId": "Customer ID",
    "businessType": "Line of business",
    "employeeCount": "Employee count",
    "assessmentforms.global.title": "Common form models",
    "participants.none": "No participants",
    "observations.mail.to": "Mail observation",
    "location.qrcode": "Observation link / QR  code",
    "location.qrcode.description": "You can copy a link to the observation or QR -code e.g. for printing.\n\nObservation link shows the selected location and its sub-locations.\n\nYou can copy the QR -code as picture using different methods. You can either save  the picture or take a screen shot and move these to e.g. presentation materials. These fuctionalities will open in most browsers when you click on the right mouse button.",
    "location.qrcode.copy": "Copy link to observation",
    "location.qrcode.copy.description": "Click here to copy observation link that you can add in e.g. presentations with paste function (e.g. right mouse button or ctrl + v).",
    "date.start": "Start date",
    "date.end": "End date",
    "locations.totals": "Data summarised (location and it's all sub locations summarized)",
    "locations.all": "Data by locations",
    "feedback.allow-session-data": "Give permission to send session data with your feedback.",
    "feedback.tooltip.session-data-explanation": "This helps by analyse the situation. Session information that will be sent contains potentially identifiable information.",
    "email.message": "Message content to recipient",
    "index": "Index",
    "type": "Type",
    "observation.id": "Observation ID",
    "task.id": "Task ID",
    "answer": "Answer",
    "table.rowsAmount": "Rows shown",
    "assessmentAnswer.not_applicable": "Not applicable",
    "error.handled-automatically": "Sorry, an unexpected error occurred. You can go back or try refreshing this page (Ctrl+F5).\n\nThis has been automatically reported to the system administrators. Supported browsers are Chrome and Firefox. If you are using some other browser, error-free usage is not guaranteed.",
    "location.defaultName": "Location",
    "collapse-all": "Collapse all",
    "expand-all": "Expand all",
    "reporter.email": "Reporter email",
    "documentation.title": "Documentation",
    "documentation.observations.description": "Observations description placeholder",
    "documentation.observations.pdf-link": "Observation PDF link placeholder",
    "documentation.observations.video-link": "Observation video link placeholder",
    "documenation.sample-link": "Sample link",
    "documentation.tasks.description": "Tasks description placeholder",
    "documentation.tasks.pdf-link": "Task PDF link placeholder",
    "documentation.assessments.description": "Assessment description placeholder",
    "documentation.monitorings.description": "Monitoring description placeholder",
    "sidebar.admin.tenant.edit": "Edit keywords (consequence/cause)",
    "observation.status.open": "Open",
    "observation.status.closed": "Closed",
    "observation.status.waiting_review": "Flagged",
    "signin.session-expired": "Your session has expired. For security reasons, your browser will refresh automatically in 5 seconds and you must log in again.",
    "changelog": "Changelog",
    "table.children.none": "No associated items",
    "location.detail.view": "Location details",
    "formError.required": "Field is required.",
    "formError.is_integer": "Field must be an integer.",
    "formError.is_positive": "Field must be positive.",
    "formError.has_length": "Field must have a length between {min} and {max}.",
    "formError.is_email": "Field must be an email.",
    "click.confirm": "Confirm by clicking again",
    "table.empty": "No relevant results found",
    "select.no_options": "No options",
    "assessmentforms.show_disabled": "Show disabled forms in the list.",
    "assessmentforms.reenable_disabled": "Re-enable",
    "assessmentforms.reenable_disabled_confirm": "Are you sure you want to re-enable form \"{name}\"?",
    "language.english": "English",
    "language.finnish": "Finnish",
    "language.swedish": "Swedish",
    "logout.auto": "You have been inactive for a while. You will be automatically logged out in {minutes} minute(s). Click close if you wish to stay logged in.",
    "missing_link.description": "Link is not working, please contact your administrator to get new link.",
    "show_statistics": "Show settings",
    "report.setFilters": "Set filters",
    "report.observations.byLocation": "Observations by location",
    "report.observations.byDate": "Observations by date",
    "report.observations.bySource": "Observations by source",
    "report.tasks.byStatus": "Tasks by status",
    "report.tasks.statusByLocation": "Task status by location",
    "report.monitorings.indexByMonth": "Monitorings index",
    "table.settings": "Table settings",
    "auditHistory": "History",
    "auditHistory.open": "View history",
    "auditHistory.changedField": "Field was changed",
    "auditHistory.deletedField": "Field was deleted",
    "auditHistory.addedField": "Field was added",
    "unknown-user": "Unknown user",
    "pdf.download": "Download PDF",
    "reports": "Reports",
    "deactivate": "Deactivate",
    "active": "Active",
    "user.tenant.inactive": "Tenant or user is inactive.",
    "users.deactivate.confirm": "Are you sure you want to deactivate user \"{name}\"?",
    "users.activate.confirm": "Are you sure you want to activate user \"{name}\"?",
    "tenants.deactivate.confirm": "Are you sure you want to deactivate tenant \"{tenant}\"?",
    "tenants.activate.confirm": "Are you sure you want to activate tenant \"{tenant}\"?",
    "users.active_change": "Status changed",
    "resource.error.title": "No such item",
    "resource.error.message": "This item has been removed or you don't have access to it.",
    "pdf.overview": "Form and descriptions",
    "pdf.detailed": "Form and noticed hazards",
    "risk.insignificant": "Insignificant risk",
    "risk.low": "Low risk",
    "risk.medium": "Medium risk",
    "risk.high": "High risk",
    "risk.extreme": "Extreme risk",
    "review.dashboard": "Dashboard",
    "review.details": "Report view",
    
    //email notification settings   
    "email.notification.settings": "Email notification settings",    
    "email.settings.taskCreated": "Task Created",
    "email.settings.taskUpdated": "Task Updated",
    "email.settings.taskIncomplete": "Task Incomplete",
    "email.settings.observationCreated": "Observation Created",
    "email.settings.observationUpdated": "Observation Updated",
    "email.settings.assessmentCreated": "Assessment Created",
    "email.settings.assessmentUpdated": "Assessment Updated",
    "email.settings.monitoringCreated": "Monitoring Created",
    "email.settings.monitoringUpdated": "Monitoring Updated",
    "email.settings.enabled": "Enabled",
    "email.settings.disabled": "Disabled",
    "email.settings.save": "Save",
  },
  "fi": {
    //new intl entries
    "only.questions": "Vain kysymykset",
    "questions.observations": "Kysymykset ja havainnot",
    "only.observations": "Vain havainnot",
    "text.search": "Tekstihaku",
    "locations.active": "Aktiiviset paikat",
    "user.rights": "Käyttäjä oikeudet",
    "user.rights.normal": "Käyttäjä",
    "user.rights.admin": "Admin",
    "tenants.active": "Aktiiviset asiakkaat",
    "fallback.question.text": "Käännös puuttuu valitulla kielellä",
    "fallback.question": "Ei kysymystä",
    "my.tasks": "Minun tehtävät",
    
    "yes": "Kyllä",
    "no": "Ei",
    "is.allright": "Onko kunnossa?",
    "comment.add": "Kommentoi",
    "cancel": "Peru",
    "created": "Luotu",
    "date": "Päivämäärä",
    "time": "Aika",
    "delete": "Poista",
    "description": "Kuvaus",
    "dueDate": "Määräpäivä",
    "dueDate.undefined": "Asettamatta",
    "due.in": "Määräaika",
    "edit": "Muokkaa",
    "form": "Lomakepohja",
    "observation": "Havainto",
    "observations": "Havainnot",
    "location": "Paikka",
    "name": "Kohteen tarkennus",
    "ok": "OK",
    "close": "Sulje",
    "priority": "Tärkeys",
    "remove": "Poista",
    "save": "Tallenna",
    "status": "Tila",
    "subject": "Haku tekstistä",
    "attachments": "Liitteet",
    "attachments.description": "Voit ladata ....",
    "task": "Toimenpide/ehdotus",
    "tasks": "Tehtävät",
    "risk": "Riski",
    "keyWords": "Seuraus/aiheuttaja",
    "low": "Matala",
    "normal": "Normaali",
    "high": "Korkea",
    "severity": "Haitallisuus",
    "minor": "Lievä",
    "moderate": "Kohtalainen",
    "severe": "Vakava",
    "assigned": "Vastuuhenkilö",
    "add.keywords": "Lisää hakusanoja",
    "reported.by": "Ilmoittaja",
    "updated.by": "Päivittänyt",
    "updated": "Päivitetty",
    "select": "Valitse",
    "section": "Osa-alue",
    "assessmentForm": "Lomakepohja",
    "assessment": "Arviointi",
    "undefined": "Ei määritetty",
    "good": "Positiivinen",
    "month": "Kuukausi",
    "reporter": "Ilmoittaja",
    "unlikely": "Satunnainen",
    "probable": "Toistuva",
    "likely": "Jatkuva",
    "probability": "Esiintyminen",
    "add.new": "Lisää uusi",
    "dateformat.invalid": "Päivämäärän muoto virheellinen",
    "start.assessment": "Aloita uusi",
    "remove.location": "Poista paikka",
    "task.open": "Avoin",
    "task.completed": "Valmis",
    "search.placeholder": "Mitä etsit?",
    "search.tooltip": "Kirjaimien ja numeroiden lisäksi voit käyttää vain välilyöntiä ja merkkiä -.",
    "riskmanagement.title": "Riskienhallinta",
    "observations.title": "Havainnot",
    "observations.new": "Uusi havainto",
    "observations.remove": "Poista havainto",
    "observations.remove.confirm": "Haluatko poistaa havainnon '{name}'? Siihen liittyvät tiedot (liitteet ja tehtävät) poistetaan järjestelmästä.",
    "observationDate": "Päivämäärä",
    "observationTime": "Kellonaika",
    "observation.new.title": "Tee uusi havainto",
    "observations.link.add": "Lisää havainto",
    "observation.subject": "Havainto",
    "observation.tasks": "Välittömät toimenpiteet",
    "observations.task.add": "Lisää tehtävä",
    "observation.description": "Kuvaus",
    "tasks.title": "Tehtävät",
    "tasks.new": "Uusi tehtävä",
    "task.new.title": "Uusi tehtävä",
    "tasks.complete": "Tehtävä valmis",
    "tasks.mail.to": "Postita tehtävä",
    "tasks.remove": "Poista tehtävä",
    "tasks.remove.confirm": "Haluatko poistaa tehtävän '{name}'? Siihen liittyvät tiedot poistetaan järjestelmästä.",
    "task.status": "Toteutus",
    "task.status.new": "Aloittamatta",
    "task.status.open": "Kesken",
    "task.status.completed": "Valmis",
    "task.status.rejected": "Ei toteuteta",
    "tasks.open": "Avoimet tehtävät",
    "assessments": "Arvioinnit",
    "assessments.title": "Arvioinnit",
    "assessments.form": "Arviointilomakepohja",
    "assessments.new": "Uusi arviointi",
    "assessment.start": "Aloita arviointi",
    "assessments.open": "Arvioinnit",
    "assessments.remove": "Poista arviointi",
    "assessments.remove.confirm": "Haluatko poistaa arvioinnin '{name}'? Siihen liittyvät tiedot (havainnot ja tehtävät) jätetään järjestelmään.",
    "assessment.create.new": "Aloita uusi",
    "assessment.status.open": "Avoin",
    "assessment.status.completed": "Valmis",
    "assessment.status.cancelled": "Peruttu",
    "assessment.name": "Kohteen tarkennus",
    "locations.title": "Paikat",
    "locations.new": "Uusi paikka",
    "location.new.title": "Uusi paikka",
    "locations.remove": "Poista paikka",
    "locations.remove.confirm": "Haluatko poistaa paikan '{name}'? Kaikki siihen liittyvät paikat sekä tiedot (havainnot ja tehtävät) poistetaan järjestelmästä.",
    "assessmentforms": "Lomakepohjat",
    "assessmentforms.title": "Lomakepohjat",
    "assessmentforms.title.addnew": "Luo uusi lomakepohja",
    "assessmentforms.new": "Uusi lomakepohja",
    "assessmentforms.remove": "Poista lomakepohja",
    "assessmentforms.remove.confirm": "Haluatko poistaa käytöstä lomakepohjan '{name}'? Arvioinnit tai seurannat, jossa lomakepohjaa on käytetty, jäävät järjestelmään, samoin niille kirjatut tiedot (havainnot ja tehtävät). Uusia arviointeja tai seurantoja ei tällä lomakepohjalla voi toteuttaa.",
    "attachment.remove": "Poista liite",
    "attachment.remove.confirm": "Haluatko poistaa liitteen?",
    "upload.add.file": "Lisää kuva",
    "upload.take.photo": "Ota kuva",
    "upload.failed": "Tiedostoa ei voitu liittää",
    "upload.failed.too.large": "Ei voitu ladata. Tiedosto on liian suuri.",
    "session.expired.title": "Vanhentunut istunto",
    "session.expired.content": "Sivu tulee ladata uudelleen",
    "reload": "Uudelleenlataa",
    "comment.comments": "Kommentit",
    "comment.comment": "Kommentoi",
    "comment.commenttext": "Jätä kommentti",
    "comment.store": "Tallenna",
    "comment.reply": "Vastaa",
    "administration.tenant": "Hallinta",
    "administration.system": "Järjestelmänhallinta",
    "users.title": "Käyttäjät",
    "users.remove": "Poista käyttäjä",
    "users.remove.confirm": "Haluatko poistaa käyttäjän '{name}'? Siihen liittyvät tiedot poistetaan järjestelmästä.",
    "tenants.title": "Asiakkaat",
    "tenants.remove": "Poista asiakas",
    "tenants.remove.confirm": "Haluatko poistaa asiakkaan '{name}'? Siihen liittyvät tiedot poistetaan järjestelmästä.",
    "tenant.new.title": "Uusi asiakas",
    "userId": "Tunnus",
    "firstName": "Etunimi",
    "lastName": "Sukunimi",
    "email": "Sähköposti",
    "tenant": "Asiakasorganisaatio",
    "questiongroups.title": "Kysymysryhmät",
    "questionGroup": "Kysymysryhmä",
    "titles.fi": "Otsikko",
    "titles.sv": "Otsikko",
    "titles.en": "Otsikko",
    "question": "Kysymys",
    "question.text": "Teksti",
    "question.instructions": "Ohjeet",
    "title": "Otsikko",
    "questionGroup.new.title": "Uusi kysymysryhmä",
    "language": "Kieli",
    "anon.observation.link": "Avoin linkki havaintoilmoitukseen ilman kirjautumista",
    "signin.heading": "Pohjola Riskienhallintapalvelu Kirjaudu",
    "signin.username": "Käyttäjätunnus",
    "signin.password": "Salasana",
    "signin.cancel": "Peruuta",
    "signin.submit": "Kirjaudu",
    "signin.invalid": "Antamasi käyttäjätunnus tai salasana on virheellinen. Palvelussa tehdyn päivityksen vuoksi edellytetään salasanojen päivittämistä. Voit uudistaa salasanasi Palauta unohtunut salasana - toiminnolla.",
    "signin.unexpected": "Jotakin odottamatonta tapahtui. Kokeile uudelleen tai ota yhteyttä pääkäyttäjään.",
    "signin.retry": "Virhe. Yritä vähän ajan kuluttua uudelleen.",
    "signin.logout": "Kirjaudu ulos",
    "signin.logout.header": "Uloskirjautuminen",
    "signin.changepwd": "Vaihda salasana",
    "signin.changepwd.old": "Vanha salasana",
    "signin.feedback": "Lähetä tästä palautetta.",
    "signin.user-expired": "Käyttäjätunnus on rauennut.",
    "register.password": "Salasana",
    "register.password_check": "Varmista salasana",
    "register.unexpected": "Jotakin odottamatonta tapahtui. Ota yhteyttä ylläpitoon.",
    "signin.error.password_format": "Salasanan pitää olla vähintään 8 merkkiä pitkä, sisältää isoja ja pieniä kirjaimia sekä numeroita.",
    "signin.error.register_password_mismatch": "Salasanat eivät täsmää",
    "signin.needsLoginFirst": "Kirjaudu väliaikaisella salasanallasi, jotta voit vaihtaa salasanasi. {signinLink}",
    "signin.signinLink": "Siirry kirjautumissivulle.",
    "user.in.limbo": "Sinulla ei ole vielä pääsyä asiakastietoihin. Tilisi rekisteröinti odottaa toimenpiteitä. Ole hyvä ja kokeile kirjautumista uudelleen myöhemmin.",
    "terms_and_conditions": "Käyttöehdot",
    "terms_and_conditions.content": "Placeholder for the terms and conditions that the user has to agree to.",
    "terms_and_conditions.accept": "Hyväksyn käyttöehdot",
    "forgotPassword": "Palauta unohtunut salasana",
    "passwordRecovery": "Palauta unohtunut salasana",
    "recoverPassword": "Palauta",
    "recovery.complete.text": "Salasanan palautuksen varmistuskoodi lähetetty sähköpostiin {email}. ",
    "recovery.tempCodeResent": "Väliaikainen salasana on lähetetty osoitteeseen {email}. Käytä salasanaa kirjautumiseen.",
    "recovery.verifylink": "Jatka varmistukseen",
    "recoveryVerify": "Salasanan palautuksen varmistus",
    "verify.code": "Varmistuskoodi",
    "verify.newpassword.explain": "Valitse uusi salasana",
    "activate": "Ota käyttöön",
    "register.validationCode": "Väärä varmistuskoodi",
    "termsAccepted": "Käyttöehdot hyväksytty",
    "done": "Tehty",
    "user": "Käyttäjä",
    "user.new.title": "Lisää uusi käyttäjä",
    "tenants": "Asiakkaat",
    "send": "Lähetä",
    "monitorings": "Seurannat",
    "monitorings.title": "Seurannat",
    "monitorings.form": "Seurantalomakepohjat",
    "monitoringForm": "Seurantalomakepohja",
    "monitorings.remove": "Poista seuranta",
    "monitorings.remove.confirm": "Haluatko poistaa seurannan '{name}'? Siihen liittyvät tiedot (havainnot ja tehtävät) jätetään järjestelmään.",
    "monitorings.new": "Aloita uusi seuranta",
    "monitorings.start": "Aloita seuranta",
    "monitorings.open": "Avoimet seurannat",
    "monitoring": "Seuranta",
    "participants": "Osallistujat",
    "participants.add": "Lisää uusi osallistuja",
    "participant": "Osallistuja",
    "form.type": "Lomakemallin tyyppi",
    "form.type.assessment": "Arviointi",
    "form.type.monitoring": "Seuranta",
    "monitorings.setcompleted": "Valmis",
    "risk.tooltip.good_undefined": "Positiivinen",
    "risk.tooltip.minor_undefined": "Lievä",
    "risk.tooltip.minor_unlikely": "Merkityksetön riski\nLievä & satunnainen",
    "risk.tooltip.minor_probable": "Pieni riski\nLievä & toistuva",
    "risk.tooltip.minor_likely": "Kohtalainen riski\nLievä & jatkuva",
    "risk.tooltip.moderate_undefined": "Haitallinen",
    "risk.tooltip.moderate_unlikely": "Pieni riski\nHaitallinen & satunnainen",
    "risk.tooltip.moderate_probable": "Kohtalainen riski\nHaitallinen & toistuva",
    "risk.tooltip.moderate_likely": "Merkittävä riski\nHaitallinen & jatkuva",
    "risk.tooltip.severe_undefined": "Vakava",
    "risk.tooltip.severe_unlikely": "Kohtalainen riski\nVakava & satunnainen",
    "risk.tooltip.severe_probable": "Merkittävä riski\nVakava & toistuva",
    "risk.tooltip.severe_likely": "Sietämätön riski\nVakava & jatkuva",
    "filter.show_all": "Näytä kaikki",
    "monitoring.status.completed": "Valmis",
    "monitoring.status.open": "Kesken",
    "monitoring.name": "Kohteen tarkennus",
    "permission.create": "Luo",
    "permission.update": "Muokkaa",
    "permission.delete": "Poista",
    "permission.read": "Lue",
    "roles": "Roolit",
    "roles.add": "Lisää rooli",
    "roles.viewer": "Luku",
    "roles.user": "Käsittely",
    "roles.manager": "Hallinnointi",
    "roles.fromParents": "Käyttäjät ylemmiltä organisaation tasoilta",
    "roles.remove": "Poista rooli",
    "roles.remove.confirm": "Haluatko poistaa roolin '{role}' käyttäjältä '{name}'?",
    "location.parent": "Ylempi organisaatiotaso",
    "select.create.option": "Uusi vaihtoehto \"{label}\"",
    "related.kept.confirm": "Seuraavat lomakkeelle liitetyt {associatedResources} säilytetään, vaikka lomakepohja poistetaan.",
    "related.none": "Tämä {resource} ei sisällä yhtään kohdetta: {associatedResources}.",
    "related.removed.confirm": "Seuraavat {associatedResources} poistetaan.",
    "admin": "Pääkäyttäjä",
    "default": "Oletus",
    "role": "Rooli",
    "admin.stats.new_observations": "Uudet havainnot",
    "admin.stats.new_tasks": "Uudet tehtävät",
    "admin.stats.new_assessments": "Uudet arvioinnit",
    "admin.stats.new_monitorings": "Uudet seurannat",
    "admin.stats.active_users": "Aktiiviset käyttäjät",
    "admin.stats.summary": "Kokonaisyhteenveto",
    "admin.stats.positive_observation_rate": "Positiivisten havaintojen osuus",
    "admin.stats.tasks_close_rate_total": "Tehtävien valmistumisaste kaikki",
    "admin.stats.tasks_close_rate_due_time": "Tehtävien valmistumisaste ajallaan",
    "admin.stats.no_deadline_tasks": "Tehtävät ilman määräpäivää",
    "admin.stats.overdue_tasks": "Myöhässä olevat tehtävät",
    "admin.stats.location_stats.title": "Kooste paikoittain",
    "admin.stats.completed_tasks": "Toteutetut tehtävät",
    "admin.stats.last_month_stats": "Edellisen kuun tilastot",
    "admin.stats.tenant_stats.title": "Yhteenveto asiakastiedoista",
    "general": "Yleinen",
    "feedback.send": "Lähetä palautetta",
    "feedback.praise": "Positiivinen palaute",
    "feedback.propose-feature": "Ehdotus",
    "feedback.problem": "Ongelma",
    "feedback.thanks": "Kiitos palautteestasi!",
    "feedback.error": "Palautteen lähettämisessä tapahtui virhe!",
    "source": "Lähde",
    "anon.thank.you": "Kiitos!",
    "notifications.settings.disable": "Älä ilmoita minulle sähköpostilla tilanteessa:",
    "notifications.task_assign": "Minulle on nimetty tehtävä",
    "notifications.observation_create": "Havainto on luotu",
    "notifications.observation_assign": "Havainto on nimetty minulle",
    "my.user.details": "Käyttäjätietoni",
    "tooltip.data-protection": "Älä kirjaa henkilötietoja tai liitä kuvia, joista henkilöt voi tunnistaa.",
    "businessId": "y-tunnus",
    "customerId": "Asiakastunnus",
    "businessType": "Toimiala",
    "employeeCount": "Henkilömäärä",
    "assessmentforms.global.title": "Yhteiset lomakepohjat",
    "participants.none": "Ei osallistujia",
    "observations.mail.to": "Lähetä havainto",
    "location.qrcode": "Havaintolinkki / QR-koodi",
    "location.qrcode.description": "Voit kopioida linkin havaintoon tai QR-koodin esim. tulostettavaksi paikalle.\n\nHavaintolinkin paikkavalikossa on valittu paikka ja sen alla oleva paikkarakenne.\n\nVoit kopioida viereisen QR-koodin kuvana eri toiminnoilla. Vaihtoehtona on tallentaa kuva tai ottaa kuvaruutukaappaus ja siirtää nämä esim. esitysmateriaaliin. Nämä toiminnot avautuvat useimmissa selaimissa hiiren oikealla painikkeella.",
    "location.qrcode.copy": "Kopioi havaintolinkki",
    "location.qrcode.copy.description": "Painamalla tästä, kopioit havaintolinkin ja voit siirtää sen esim. esitysmateriaaliin liitä -toiminnolla (esim. hiiren oikealla painikkeella tai ctrl + v).\n",
    "date.start": "Alku",
    "date.end": "Loppu",
    "locations.totals": "Tiedot yhteensä (paikka ja alapaikat yhteenlaskettuna)",
    "locations.all": "Kaikki tiedot paikoittain",
    "feedback.allow-session-data": "\n\n\n\n\n\n\n\n\n\n\nSalli istuntoon liittyvien lisätietojen lähetys.",
    "feedback.tooltip.session-data-explanation": "Tämä auttaa tilanteen selvittämisessä. Lähetettävät tiedot saattavat sisältää yksilöiviä tietoja istunnosta.",
    "email.message": "Saateviesti vastaanottajalle",
    "index": "Indeksi",
    "type": "Tyyppi",
    "observation.id": "Havaintonumero",
    "task.id": "Tehtävänumero",
    "answer": "Vastaus",
    "table.rowsAmount": "Rivien määrä",
    "assessmentAnswer.not_applicable": "Ei arvioida",
    "error.handled-automatically": "Jotain odottamatonta tapahtui. Voit palata tai yrittää päivittää sivun uudelleen (Ctrl+F5).\n\nTästä on automaattisesti lähetetty viesti järjestelmän pääkäyttäjille. Palvelun käyttöön suositellaan Chromea tai Firefoxia. Jos käytät jotain muuta selainta, kaikki toiminnot eivät mahdollisesti ole käytettävissä.",
    "location.defaultName": "Paikka",
    "collapse-all": "Piilota kaikki",
    "expand-all": "Näytä kaikki",
    "reporter.email": "Sähköpostiosoite",
    "documentation.title": "Ohjeet",
    "sidebar.admin.tenant.edit": "Muokkaa hakusanoja (seuraus/aiheuttaja)",
    "observation.status.open": "Avoin",
    "observation.status.closed": "Valmis",
    "observation.status.waiting_review": "Seurannassa",
    "signin.session-expired": "Istuntosi on vanhentunut. Tietoturvasyistä selaimesi päivittyy automaattisesti viiden sekunnin kuluttua ja sinun pitää kirjautua uudelleen.",
    "changelog": "Muutoshistoria",
    "table.children.none": "Ei kirjattuja havaintoja",
    "location.detail.view": "Paikan tiedot",
    "formError.required": "Kenttä on pakollinen.",
    "formError.is_integer": "Käytä kokonaislukua.",
    "formError.is_positive": "Kentän arvon oltava positiivinen.",
    "formError.has_length": "Kenttään syötettävä vähintään {min} ja enintäään {max} merkkiä.",
    "formError.is_email": "Tarkista sähköpostiosoite.",
    "click.confirm": "Vahvista muutos.",
    "table.empty": "Ei valintojen mukaisia tietoja",
    "select.no_options": "Ei valintoja",
    "assessmentforms.show_disabled": "Näytä listalla myös käytöstä poistetut lomakkeet.",
    "assessmentforms.reenable_disabled": "Palauta käyttöön",
    "assessmentforms.reenable_disabled_confirm": "Haluatko palauttaa lomakkeen \"{name}\" uudelleen käyttöön?",
    "language.english": "Englanti",
    "language.finnish": "Suomi",
    "language.swedish": "Ruotsi",
    "logout.auto": "Et ole ollut aktiivinen palvelussa hetkeen. Istuntosi suljetaan automaattisesti {minutes} minuutin kuluttua. Valitse \"Sulje\", jos haluat pitää kirjautumisen aktiivisena.",
    "missing_link.description": "Linkki ei toimi, otathan yhteyttä pääkäyttäjään uuden linkin saamiseksi.",
    "show_statistics": "Näytä asetukset",
    "report.setFilters": "Valitse rajaukset",
    "report.observations.byLocation": "Havainnot paikoittain",
    "report.observations.byDate": "Havainnot kuukausittain",
    "report.observations.bySource": "Havainnon lähde",
    "report.tasks.byStatus": "Tehtävät tilan mukaan",
    "report.tasks.statusByLocation": "Tehtävät paikoittain tilan mukaan",
    "report.monitorings.indexByMonth": "Seurantojen indeksi",
    "table.settings": "Taulukon asetukset",
    "auditHistory": "Muutokset",
    "auditHistory.open": "Tarkastele muutoksia",
    "auditHistory.changedField": "Kentän tietoa muutettu",
    "auditHistory.deletedField": "Kenttä on poistettu",
    "auditHistory.addedField": "Kenttä on lisätty",
    "unknown-user": "Tuntematon käyttäjä",
    "pdf.download": "Tulosta PDF",
    "reports": "Raportit",
    "deactivate": "Pois käytöstä",
    "active": "Käytössä",
    "user.tenant.inactive": "Organisaatio tai käyttäjä on ei-aktiivinen.",
    "users.deactivate.confirm": "Oletko varma, että haluat muuttaa käyttäjän \"{name}\" tilan ei-aktiiviseksi?",
    "users.activate.confirm": "Oletko varma, että haluat muuttaa käyttäjän \"{name}\" tilan aktiiviseksi?",
    "tenants.deactivate.confirm": "Oletko varma, että haluat muuttaa organisaation \"{tenant}\" tilan ei-aktiiviseksi?",
    "tenants.activate.confirm": "Oletko varma, että haluat muuttaa organisaation \"{tenant}\" tilan aktiiviseksi?",
    "users.active_change": "Tila muutettu",
    "resource.error.title": "Kohdetta ei ole olemassa",
    "resource.error.message": "Kohde on poistettu tai sinulla ei ole siihen oikeutta.",
    "pdf.overview": "Lomake ja selitystekstit",
    "pdf.detailed": "Lomake ja tunnistetut haittatekijät",
    "risk.insignificant": "Merkityksetön riski",
    "risk.low": "Pieni riski",
    "risk.medium": "Kohtalainen riski",
    "risk.high": "Merkittävä riski",
    "risk.extreme": "Sietämätön riski",
    "review.dashboard": "Koostesivu",
    "review.details": "Raporttinäkymä",
        
    //email notification settings   
    "email.notification.settings": "Sähköposti ilmoitusten asetukset",    
    "email.settings.taskCreated": "Tehtävä listätty,",
    "email.settings.taskUpdated": "Tehtävä päivitetty",
    "email.settings.taskIncomplete": "Tehtävä keskeräinen",
    "email.settings.observationCreated": "Havainto luotu",
    "email.settings.observationUpdated": "Havainto päivitetty",
    "email.settings.assessmentCreated": "Arvionti luotu",
    "email.settings.assessmentUpdated": "Arviointi päivitetty",
    "email.settings.monitoringCreated": "Seuranta luotu",
    "email.settings.monitoringUpdated": "Seuranta päivitetty",
    "email.settings.enabled": "Käytössä",
    "email.settings.disabled": "Ei käytössä",
    "email.settings.save": "Tallenna",
  },
  "sv": {
    //new intl entries
    "only.questions": "Bara frågor",
    "questions.observations": "Frågor och observationer",
    "only.observations": "Bara observationer",
    "text.search": "Textsökning",
    "locations.active": "Aktiva platser",
    "user.rights": "Användarrättigheter",
    "user.rights.normal": "Användare",
    "user.rights.admin": "Admin",
    "tenants.active": "Aktiva kunder",
    "fallback.question.text": "Översättning saknas för valt språk",
    "fallback.question": "Ingen fråga",
    "my.tasks": "Mina Uppdrag",

    "yes": "Ja",
    "no": "Nej",
    "is.allright": "Är det i skick?",
    "comment.add": "Kommentera",
    "cancel": "Avbryt",
    "created": "Skapat",
    "date": "Datum",
    "time": "Tid",
    "delete": "Radera",
    "description": "Beskrivning",
    "dueDate": "Färdig senast",
    "dueDate.undefined": "Obestämd",
    "due.in": "Tidsfrist",
    "edit": "Redigera",
    "form": "Formulärmodell",
    "observation": "Observation",
    "observations": "Observationer",
    "location": "Plats",
    "name": "Fokuserat objekt ",
    "ok": "Ok",
    "close": "Stäng",
    "priority": "Prioritet",
    "remove": "Radera",
    "save": "Spara",
    "status": "Tillstånd",
    "subject": "Sök från text",
    "attachments": "Bilagor",
    "attachments.description": "Du kan ladda ned ....",
    "task": "Åtgärd/förslag",
    "tasks": "Uppdrag",
    "risk": "Risk",
    "keyWords": "Konsekvens/orsak",
    "low": "Låg",
    "normal": "Normal",
    "high": "Hög",
    "severity": "Skadlighet",
    "minor": "Lindrig",
    "moderate": "Måttlig",
    "severe": "Allvarlig",
    "assigned": "Ansvarig person",
    "add.keywords": "Lägg till sökord",
    "reported.by": "Anmälare",
    "updated.by": "Uppdaterat av",
    "updated": "Uppdaterad",
    "select": "Välj",
    "section": "Sektion",
    "assessmentForm": "Formulärmodell",
    "assessment": "Bedömning ",
    "undefined": "Odefinierad",
    "good": "Positiv",
    "month": "Månad",
    "reporter": "Anmälare",
    "unlikely": "Slumpmässig",
    "probable": "Upprepande",
    "likely": "Konstant",
    "probability": "Förekommande",
    "add.new": "Lägg till ny",
    "dateformat.invalid": "Fel format av datum",
    "start.assessment": "Starta nytt",
    "remove.location": "Ta bort plats",
    "task.open": "Öppen",
    "task.completed": "Avslutad",
    "search.placeholder": "Vad söker du?",
    "search.tooltip": "Utöver bokstäver och siffror kan du endast använda  mellanslag och tecknet -.",
    "riskmanagement.title": "Riskhantering",
    "observations.title": "Observationer",
    "observations.new": "Ny observation",
    "observations.remove": "Ta bort observationen",
    "observations.remove.confirm": "Vill du ta bort observationen '{name}'? Allt relaterad data (bilagor och uppdrag) kommer att tas bort.",
    "observationDate": "Datum",
    "observationTime": "Tid",
    "observation.new.title": "Lägg till ny observation",
    "observations.link.add": "Lägg till en observation",
    "observation.subject": "Observation",
    "observation.tasks": "Omedelbara åtgärder",
    "observations.task.add": "Lägg till uppdragt",
    "observation.description": "Beskrivning",
    "tasks.title": "Uppdrag",
    "tasks.new": "Ny uppdrag",
    "task.new.title": "Ny uppdrag",
    "tasks.complete": "Slutför uppdrag",
    "tasks.mail.to": "Skicka uppdrag",
    "tasks.remove": "Ta bort uppdrag",
    "tasks.remove.confirm": "Vill du ta bort uppdraget '{name}'? Allt relaterad data kommer att tas bort.",
    "task.status": "Förverkligande",
    "task.status.new": "Opåbörjad",
    "task.status.open": "Halvfärdig",
    "task.status.completed": "Färdig",
    "task.status.rejected": "Ej förverkligad",
    "tasks.open": "Öppna uppdrag",
    "assessments": "Bedömningar",
    "assessments.title": "Bedömningar",
    "assessments.form": "Bedömningsformulär",
    "assessments.new": "Ny bedömning",
    "assessment.start": "Påbörja bedömningen",
    "assessments.open": "Bedömningar",
    "assessments.remove": "Ta bort bedömning",
    "assessments.remove.confirm": "Vill du ta bort bedömningen '{name}'? Allt relaterad data (observationer och uppdrag) kommer att lämnas.",
    "assessment.create.new": "Påbörja ny",
    "assessment.status.open": "Öppen",
    "assessment.status.completed": "Färdig",
    "assessment.status.cancelled": "Inställd",
    "assessment.name": "Fokuserat objekt ",
    "locations.title": "Platser",
    "locations.new": "Ny plats",
    "location.new.title": "Ny plats",
    "locations.remove": "Ta bort platsen",
    "locations.remove.confirm": "Vill du ta bort platsen '{name}'? Alla samhörande platser och data (observationer och uppdrag) kommer att tas bort.",
    "assessmentforms": "Formulärmodell",
    "assessmentforms.title": "Formulärmodell",
    "assessmentforms.title.addnew": "Skapa ny formulärmodell",
    "assessmentforms.new": "Ny formulärmodell",
    "assessmentforms.remove": "Ta bort formulärmodell",
    "assessmentforms.remove.confirm": "Vill du ta bort formulärmodellet '{name}'? Bedömningar och uppföljningar som har gjorts med detta formulärmodell skall lämnas, liksom allt angående data (observationer och uppdrag). Nya bedömningar eller uppföljningar kan inte skapas med formulärmodellet.",
    "attachment.remove": "Ta bort bilaga",
    "attachment.remove.confirm": "Vill du ta bort bilagan?",
    "upload.add.file": "Lägg till bild",
    "upload.take.photo": "Ta bild",
    "upload.failed": "Filen kunde inte bifogas",
    "upload.failed.too.large": "Filen för stor att uppladdas",
    "session.expired.title": "Inloggningssessionen föråldrad",
    "session.expired.content": "Sidan skall laddas om",
    "reload": "Ladda om",
    "comment.comments": "Kommentarer",
    "comment.comment": "Kommentera",
    "comment.commenttext": "Lämna kommentar",
    "comment.store": "Skicka ",
    "comment.reply": "Svara",
    "administration.tenant": "Administration",
    "administration.system": "System Administration",
    "users.title": "Användare",
    "users.remove": "Ta bort användare",
    "users.remove.confirm": "Vill du ta bort användare '{name}'? Allt relaterad data kommer att tas bort.",
    "tenants.title": "Kunder",
    "tenants.remove": "Ta bort kund",
    "tenants.remove.confirm": "Vill du ta bort kunden '{name}'? Allt relaterad data kommer att tas bort.",
    "tenant.new.title": "Ny kund",
    "userId": "Användarenamn",
    "firstName": "Förnamn",
    "lastName": "Efternamn",
    "email": "E-post",
    "tenant": "Kundorganisationer",
    "questiongroups.title": "Förfrågningsgrupper",
    "questionGroup": "Förfrågningsgrupp ",
    "titles.fi": "Rubrik",
    "titles.sv": "Rubrik",
    "titles.en": "Rubrik",
    "question": "Fråga",
    "question.text": "Text",
    "question.instructions": "Anvisningar",
    "title": "Rubrik",
    "questionGroup.new.title": "Ny förfrågningsgrupp",
    "language": "Språk",
    "anon.observation.link": "Öppen länk till anmälan utan inloggning",
    "signin.heading": "Pohjola Riskhanteringstjänst Logga in",
    "signin.username": "Användarnamn",
    "signin.password": "Lösenord",
    "signin.cancel": "Ångra",
    "signin.submit": "Logga in",
    "signin.invalid": "Fel i användarkoden eller lösenordet. Också på grund av tjänstens uppdatering bör alla lösenord förnyas. Använd Återför glömt lösenord – funktionen för att uppdatera lösenordet.",
    "signin.unexpected": "Någonting oförutsedd har hänt. Vänlingen försöka på nytt eller kontakta administrator.",
    "signin.retry": "Fel. Vänligen försök på nytt senare.",
    "signin.logout": "Logga ut",
    "signin.logout.header": "Utcheckning",
    "signin.changepwd": "Ändra lösenord",
    "signin.changepwd.old": "Gammalt lösenord",
    "signin.feedback": "Skicka feedback här.",
    "signin.user-expired": "User account has expired.",
    "register.password": "Lösenord",
    "register.password_check": "Fastställa lösenord",
    "register.unexpected": "Någonting oförutsedd har hänt. Vänlingen kontakta administrator.",
    "signin.error.password_format": "Lösenordet måste vara minst 8 tecken långt och måste innehålla små bokstäver, stora bokstäver och siffror.",
    "signin.error.register_password_mismatch": "Lösenorden stämmer inte ihop",
    "signin.needsLoginFirst": "Logga in med tilfälliga lösenord for at förändra lösenordet. {signinLink}",
    "signin.signinLink": "Gå till inloggingssida.",
    "user.in.limbo": "Du har ingen ingång på klientdata ännu. Vänligen vänta att ditt konto är registrerat.",
    "terms_and_conditions": "Användarvillkor",
    "terms_and_conditions.content": "Placeholder for the terms and conditions that the user has to agree to.",
    "terms_and_conditions.accept": "Jad godkänner användarvillkoren",
    "forgotPassword": "Återför glömt lösenord",
    "passwordRecovery": "Återför glömt lösenord",
    "recoverPassword": "Återför glömt lösenord",
    "recovery.complete.text": "Säkringskoden till återföring av lösenordet skickat till e-post {email}. ",
    "recovery.tempCodeResent": "Tilfälliga lösenord skickat till e-post {email}. Vänligen logga in med skickat lösenord.",
    "recovery.verifylink": "Fortsätt till säkring",
    "recoveryVerify": "Säkerställning av återförd lösenord",
    "verify.code": "Säkringskod",
    "verify.newpassword.explain": "Välj nytt lösenord",
    "activate": "Ta i bruk",
    "register.validationCode": "Fel säkringskod",
    "termsAccepted": "Användarvillkoren godkänt",
    "done": "Klar",
    "user": "Användare",
    "user.new.title": "Lägg till ny användare",
    "tenants": "Kunder",
    "send": "Skicka",
    "monitorings": "Uppföljningar",
    "monitorings.title": "Uppföljningar",
    "monitorings.form": "Uppföljningsformulärmodeller",
    "monitoringForm": "Uppföljningsformulärmodell",
    "monitorings.remove": "Ta bort uppföljning",
    "monitorings.remove.confirm": "Vill du ta bort uppföljningsformulär '{name}'? Allt relaterad data (observationer och uppdrag) kommer att lämnas.",
    "monitorings.new": "Börja ny uppföljning",
    "monitorings.start": "Börja uppföljning",
    "monitorings.open": "Öppna uppföljningar",
    "monitoring": "Uppföljning",
    "participants": "Deltagare",
    "participants.add": "Lägg in ny deltagare",
    "participant": "Deltagare",
    "form.type": "Formulärmodell typ",
    "form.type.assessment": "Bedömning",
    "form.type.monitoring": "Uppföljning",
    "monitorings.setcompleted": "Färdig",
    "risk.tooltip.good_undefined": "Positiv",
    "risk.tooltip.minor_undefined": "Lindrig",
    "risk.tooltip.minor_unlikely": "Obetydlig risk\nLindrig & slumpmässig",
    "risk.tooltip.minor_probable": "Liten risk\nLindrig & upprepande",
    "risk.tooltip.minor_likely": "Måttlig risk\nLindrig & kontinuerlig",
    "risk.tooltip.moderate_undefined": "Störande",
    "risk.tooltip.moderate_unlikely": "Liten risk\nStörande & slumpmässig",
    "risk.tooltip.moderate_probable": "Måttlig risk\nStörande & upprepande",
    "risk.tooltip.moderate_likely": "Betydande risk\nStörande & kontinuerlig",
    "risk.tooltip.severe_undefined": "Allvarlig",
    "risk.tooltip.severe_unlikely": "Måttlig risk\nAllvarlig & slumpmässig",
    "risk.tooltip.severe_probable": "Betydande risk\nAllvarlig & upprepande",
    "risk.tooltip.severe_likely": "Outhärldlig risk\nAllvarlig & kontinuerlig",
    "filter.show_all": "Visa allt",
    "monitoring.status.completed": "Färdig",
    "monitoring.status.open": "Halvfärdig",
    "monitoring.name": "Fokuserat objekt ",
    "permission.create": "Skapa",
    "permission.update": "Bearbeta",
    "permission.delete": "Ta bort",
    "permission.read": "Läs",
    "roles": "Roller",
    "roles.add": "Lägga till rollet",
    "roles.viewer": "Läsning",
    "roles.user": "Hantering",
    "roles.manager": "Administrering",
    "roles.fromParents": "Roller från högre organisationsnivå",
    "roles.remove": "Ta bort rollen",
    "roles.remove.confirm": "Vill du ta bort rollen '{name}' från användare '{name}'?",
    "location.parent": "Högre organisationsnivå",
    "select.create.option": "Nytt alternativ \"{label}\"",
    "related.kept.confirm": "Följande {associatedResources} skall förvaras, även om formulärmodellen skall tas bort.",
    "related.none": "{resource} innehåller inga {associatedResources}.",
    "related.removed.confirm": "Följande {associatedResources} skall tas bort.",
    "admin": "Huvudanvändare",
    "default": "Förmodning",
    "role": "Roll",
    "admin.stats.new_observations": "Nya observationer",
    "admin.stats.new_tasks": "Nya uppdrag",
    "admin.stats.new_assessments": "Nya bedömningar ",
    "admin.stats.new_monitorings": "Nya uppföljningar",
    "admin.stats.active_users": "Aktiva användare",
    "admin.stats.summary": "Total sammandrag",
    "admin.stats.positive_observation_rate": "Antal positiva observationer",
    "admin.stats.tasks_close_rate_total": "Uppdragens färdigställandes grad",
    "admin.stats.tasks_close_rate_due_time": "Uppdragens färdigställandes grad inom tidsram",
    "admin.stats.no_deadline_tasks": "Antal uppdrag utan slutdatum",
    "admin.stats.overdue_tasks": "Försenade uppdrag",
    "admin.stats.location_stats.title": "Sammandrag platsvis",
    "admin.stats.completed_tasks": "Utförda uppdragen",
    "admin.stats.last_month_stats": "Sista månadens statistik",
    "admin.stats.tenant_stats.title": "Sammandrag av kunduppgifter",
    "general": "Generell",
    "feedback.send": "Skicka feedback",
    "feedback.praise": "Positiv",
    "feedback.propose-feature": "Förslag",
    "feedback.problem": "Problem",
    "feedback.thanks": "Tack för din feedback!",
    "feedback.error": "Det gick något fel på feedback avsändning!",
    "source": "Källan",
    "anon.thank.you": "Tack!",
    "notifications.settings.disable": "Informera mig inte med epost när:",
    "notifications.task_assign": "Uppdraget är designerat till mig",
    "notifications.observation_create": "Observationen har skapats",
    "notifications.observation_assign": "Observationen är designerat till mig",
    "my.user.details": "Min användaredata",
    "tooltip.data-protection": "Ta inte upp personlig data eller bilder, där människor kan identifieras.",
    "businessId": "FO-nummer",
    "customerId": "Kund ID",
    "businessType": "Bransch",
    "employeeCount": "Personalens antal",
    "assessmentforms.global.title": "Allmänna formulär",
    "participants.none": "Inga deltagare",
    "observations.mail.to": "Skicka observationen",
    "location.qrcode": "Observationens länk / QR-kod",
    "location.qrcode.description": "Du kan kopiera länken till den gjorda observationen eller kopiera en QR-kod t.ex. för utskrift.\n\nPlatslistan innehåller den valda platsen samt dess understrukturer.\n\nDu kan kopiera QR-koden som en bild med olika funktioner. Ett alternativ är att spara en bild eller ta en skärmdump och överföra den t.ex. till ett presentationsmaterial. Dessa funktioner öppnas i de flesta webbläsare med höger musknapp.",
    "location.qrcode.copy": "Kopiera observationens länk",
    "location.qrcode.copy.description": "Tryck här, så kopierar du observationslänken och kan överföra den t.ex. till presentationen med klistra in funktionen (t.ex. höger musknapp eller ctrl +v).",
    "date.start": "Start",
    "date.end": "Slut",
    "locations.totals": "All samlad data (plats och alla dess underplatser sammanräcknade)",
    "locations.all": "Alla data platsvis",
    "feedback.allow-session-data": "Tilllåt sändning av tilläggsinformation angående sessionen.",
    "feedback.tooltip.session-data-explanation": "Detta hjälper med utredande av situationen. Tilläggsinformationen kan innehålla personliga uppgifter angående sessionen.",
    "email.message": "Bifogat meddelande till mottagaren",
    "index": "Index",
    "type": "Typ",
    "observation.id": "Observationens ID",
    "task.id": "Uppdragets ID",
    "answer": "Ansvar",
    "table.rowsAmount": "Antal rader",
    "assessmentAnswer.not_applicable": "Ej tillgänglig",
    "error.handled-automatically": "Någonting oförutsedd har hänt. Information har sänts till systemansvariga. Du kan försöka på nytt eller uppdatera sidan (Ctrl+F5).",
    "location.defaultName": "Plats",
    "collapse-all": "Göm alla",
    "expand-all": "Visa alla",
    "reporter.email": "E-post",
    "documentation.title": "Anvisningar",
    "sidebar.admin.tenant.edit": "Redigera sökord (konsekvens/orsak)",
    "observation.status.open": "Öppen",
    "observation.status.closed": "Färdig",
    "observation.status.waiting_review": "Upfölj",
    "signin.session-expired": "Inloggningssessionen föråldrad. På grund av säkerhet, din browser skall förnyas automatisk om fem sekunder och su måste logga in på nytt.",
    "changelog": "Alterationshistoria",
    "table.children.none": "Inga uppförda observationer",
    "location.detail.view": "Uppgifter om platsen",
    "formError.required": "Fält är obligatorisk.",
    "formError.is_integer": "Använd heltal.",
    "formError.is_positive": "Fält värde måste vara positiv.",
    "formError.has_length": "Kenttään syötettävä vähintään {min} ja enintäään {max} merkkiä.",
    "formError.is_email": "Revidera e-post.",
    "click.confirm": "Bekräfta ändring.",
    "table.empty": "Inga val tillgängliga",
    "select.no_options": "Inget val",
    "assessmentforms.show_disabled": "Visa också deaktiverade formulär på listan.",
    "assessmentforms.reenable_disabled": "Återställ i bruk",
    "assessmentforms.reenable_disabled_confirm": "Vill du återställa formuläret \"{name} i bruk igen?",
    "language.english": "Engelska",
    "language.finnish": "Finsk",
    "language.swedish": "Svenska",
    "logout.auto": "Du har inte varit aktiv i tjänsten på en stund. Din session stängs automatiskt om {minutes} minuter. Välj ”Stäng” ifall du vill hålla inloggningen aktivt.",
    "missing_link.description": "Länken fungerar inte, vänligen ta kontakt med huvudanvändare för att få en ny länk.",
    "show_statistics": "Visa inställningar",
    "report.setFilters": "Bestämma filter",
    "report.observations.byLocation": "Observationer per plats",
    "report.observations.byDate": "Observationer per plats",
    "report.observations.bySource": "Observationer per källan",
    "report.tasks.byStatus": "Uppgifter enligt status",
    "report.tasks.statusByLocation": "Uppgifter platsvis enligt status",
    "report.monitorings.indexByMonth": "Uppföljningars index",
    "table.settings": "Tabell inställningar",
    "auditHistory": "Förändringar",
    "auditHistory.open": "Observera förändringar",
    "auditHistory.changedField": "Data i fältet har förändrats",
    "auditHistory.deletedField": "Fältet är avlagd",
    "auditHistory.addedField": "Fältet är tillagd",
    "unknown-user": "Okänd användare",
    "pdf.download": "Ladda PDF",
    "reports": "Rapporter",
    "deactivate": "Avställa ur bruk",
    "active": "I bruk",
    "user.tenant.inactive": "Organisationen eller användare är inaktiv.",
    "users.deactivate.confirm": "Vill du förändra status för användare \"{name}\" inaktiv?",
    "users.activate.confirm": "Vill du förändra status för användare \"{name}\" aktiv?",
    "tenants.deactivate.confirm": "Vill du förändra status för organisationen \"{tenant}\" inaktiv?",
    "tenants.activate.confirm": "Vill du förändra status för organisationen \"{tenant}\" aktiv?",
    "users.active_change": "Status förändrat",
    "resource.error.title": "Föremål finns inte",
    "resource.error.message": "Föremål är avlagd eller du inte har rättighet.",
    "pdf.overview": "Formulär och beskriviningstext",
    "pdf.detailed": "Formulär och identifierade riskfaktorer",
    "risk.insignificant": "Obetydlig risk",
    "risk.low": "Liten risk",
    "risk.medium": "Måttlig risk",
    "risk.high": "Betydande risk",
    "risk.extreme": "Outhärldlig risk",
    "review.dashboard": "Sammanställning",
    "review.details": "Rapport utsikt",

    //email notification settings   
    "email.notification.settings": "Inställningar för e-postmeddelanden",    
    "email.settings.taskCreated": "Uppdrag skapad",
    "email.settings.taskUpdated": "Uppdrag uppdaterad",
    "email.settings.taskIncomplete": "Uppdrag ofullständig",
    "email.settings.observationCreated": "Observation skapad",
    "email.settings.observationUpdated": "Observation uppdaterad",
    "email.settings.assessmentCreated": "Bedömning skapad",
    "email.settings.assessmentUpdated": "Bedömning uppdaterad",
    "email.settings.monitoringCreated": "Övervakning skapad",
    "email.settings.monitoringUpdated": "Övervakning uppdaterad",
    "email.settings.enabled": "Aktiverad",
    "email.settings.disabled": "Inaktiverad",
    "email.settings.save": "Spara",
  }
};
